import { EntityId } from './entity-id';

export class Alarm {
    id : EntityId;
    createdTime : number;
    tenantId : EntityId;
    type : string;
    originator : EntityId;
    severity: string;
    status: string;
    startTs: number;
    endTs: number;
    ackTs: number;
    clearTs: number;
    details: any;
    propagate: any;
    propagateRelationTypes:any;
    originatorName: string;
    name: string; //message
}

export class AlarmLists{
    data : Alarm[]=[];
    nextPageLink : any;
    hasNext : boolean;
}