import { Component } from '@angular/core';

import { Platform, AlertController, MenuController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router, NavigationExtras } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@ionic/storage';
import { UserAuth, UserInfo } from './models/user-info';
import { Device } from '@capacitor/core';
import { MainNewPage } from './main-new/main-new.page';
import { ThingsboardService } from './services/thingsboard.service';
import { AlarmLists } from './models/alarm';
import { AssetLists } from './models/assets';
import { MenuChangeService } from './services/menu-change.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  navigate : any;
  isEn : any;

  is_web : boolean = false;

  is_login : boolean =false;
  assetType :any="";

  alarm_interval :any;
  devType1 : any="";
  alarms : AlarmLists = new AlarmLists();
  alarm_assets : AssetLists = new AssetLists();
  alarm_cnt : any=0;
  userInfo : UserInfo = new UserInfo();

  login_subscription :any;
  devType_subscription : any;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authenticationService: AuthenticationService,
    private router: Router,
    public alertCtrl : AlertController,
    private translate: TranslateService, // add this
    private storage : Storage,
    private menuCtrl : MenuController,
    private thingsboard : ThingsboardService,
    private menuChangeService : MenuChangeService
  ) {
    this.initializeApp();
    this.login_subscription = menuChangeService.loginPassceSource$.subscribe(
      (u_data : UserInfo)=>{
        console.log("Login subscribe");
        console.log(u_data);
        if (u_data != null){
          this.userInfo = u_data;
        }
      }
    );
    this.devType_subscription = menuChangeService.devTypeChangeSource$.subscribe(
      (d_type) =>{
        this.devType1 = d_type;
      } 
    )
  }

  checkAlarm(){
    console.log("CheckAlarm");
    this.storage.get("profile").then(
      (uInfo)=>{
        this.userInfo = uInfo;
        this.storage.get("auth-token").then(
          async (tk) =>{
            
            // this.thingsboard.fetchAlarm(tk, uInfo.assetType).subscribe(
            //     (res)=>{
            //         this.storage.set("alarms", res);
            //     }

            // )
            this.storage.get("ASSETS").then(
              (res_asset)=>{
                if (res_asset == null){
                  return;
                }
                this.alarm_assets = res_asset;
                let idx = 0;
                let max_alarm_assets = 0;
                if (this.alarm_assets.data!=null){
                   max_alarm_assets = this.alarm_assets.data.length;
                }
                this.alarm_cnt = 0;
                for (let alarm_asset of this.alarm_assets.data){
                  this.thingsboard.fetchAlarm(tk, alarm_asset.id.id).subscribe(
                    (alarms)=>{
                      alarm_asset.alarms = alarms.data;
                      
                      if (this.alarm_assets.data != null && this.alarm_assets.data.length >0){
                        
                        //map device withi alarm
                        for (let alarm1 of alarm_asset.alarms){
                          for (let d_a of alarm_asset.devices){
                            if (alarm1.originator.entityType==='DEVICE' && alarm1.originator.id === d_a.id.id){
                              d_a.hasAlarm = true;
                              this.alarm_cnt = 1;
                              break;
                            }
                          }
                        }
                        
                      }
                      this.storage.set("ALARM_ASSET", this.alarm_assets).then(
                        ()=>{
                          idx++;
                          if (idx >= max_alarm_assets){
                            return;
                          }
                        }
                      )
                    }

                  )
              
                   

                }
              }
            )
          }
        );
      }
    );
    
  
  }
  setAlarmInterval(){
    console.log("start alarm interval");
    this.alarm_interval= setInterval(()=> { this.checkAlarm() }, 900000);
  }

  clearCheckAlarm(){
    clearInterval(this.alarm_interval);
  }
  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      Device.getInfo().then(
        (inf)=>{
          console.log("platform=>" + inf.platform);
            if (inf.platform.indexOf("web") > -1){
              this.is_web = true;
            }
      });
      this.authenticationService.authenticationState.subscribe(state => {
        //console.log("state=>" + state);
        if (state) {
          this.storage.get("profile").then(res => {
              this.assetType = res.assetType;
              this.is_login = true;
              this.userInfo = res;
              this.setNavigator();
              if (res != null && res.assetType == "PUMP"){
                this.devType1 = 'PUMP';
                this.setAlarmInterval();
                this.router.navigate(['main-new']);
                
              }else if (res != null && res.assetType == "LIGHT"){
                this.devType1 = 'LIGHT';
                this.setAlarmInterval();
                this.router.navigate(['main-new']);

               
              }else if (res.assetType == null){
                
                this.setAlarmInterval();
                this.router.navigate(['sel-asset-type']);
              }
          }
          );
          
        } else {
          this.clearCheckAlarm();
          //web-section
          // if (this.platform.width() > 768){ //if (this.platform.width() > 576){
          //   this.router.navigate(['login-web']);
          // }else{
          // this.router.navigate(['login']);
          // }
          this.router.navigate(['login']);
        }
      });
    });
    this.translate.setDefaultLang('th'); // add this
    this.isEn ='th';
    this.sideMenu();
  }

  async viewAlarm(){
    let navigationExtras: NavigationExtras = {
      state: {
        alarm_assets : this.alarm_assets,
        backpage:  null
      }
    };
    console.log("route to alarm list");
    
    this.router.navigate(['alarm-list'],navigationExtras);
  }
  async openPage(p){
    //console.log(p.url);
    if (p.url == "/logout"){

      let alert =  await this.alertCtrl.create({
        message: 'Do you want to logout?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
              //console.log('Cancel clicked');
              this.menuCtrl.close();
              this.router.navigate(['main-new']);
            }
          },
          {
            text: 'OK',
            handler: () => {
              this.is_login = false;
              this.assetType = "";
              this.menuCtrl.close();
              this.clearCheckAlarm();
              this.authenticationService.logout();
            }
          }
        ]
      });
      alert.present();


    }if (p.url == "/sel-asset-type"){

      //console.log(this.platform.width());
      if (this.platform.width() >768){ //if (this.platform.width() >576){
        //web
        this.menuCtrl.enable(false);
        //web section
        //this.router.navigate(['sel-asset-web']);
        this.router.navigate(['sel-asset-type']);
      }else{
        this.menuCtrl.close();
        this.router.navigate(['sel-asset-type']);
      }

    }else{
        this.menuCtrl.close();
         this.router.navigate([p.url]);
      
    }

  }

  setNavigator(){
    if (this.userInfo != null && this.userInfo.additionalInfo !=  null && this.userInfo.additionalInfo.description === "P" ) {
          this.navigate =
          [
            {
              title: 'MENU.main',
              url: '/main-new',
              color: '#0984e3',
              icon : 'home'
            },
            {
              title: 'MENU.asset',
              url: '/asset-lists',
              color: '#ff7675',
              icon : 'map'
            },
            {
              title: 'MENU.device',
              url: '/device-lists',
              color: '#ff7675',
              icon: 'water'
            },
            {
              title: 'MENU.sel-asset',
              url: '/sel-asset-type',
              color: '#00cec9',
              icon: 'toggle'
            },
            {
              title: 'MENU.contractor',
              url: '/add-device',
              icon: 'add-circle'
            }, 
            {
              title: 'MENU.alarm',
              url: '/alarm-list',
              color: '#00cec9',
              icon: 'alert'
            },
            {
              title: 'MENU.logout',
              url:'/logout',
              color : '#2d3436',
              icon : 'log-out'
            }
          ]
  }else{
    this.navigate =
    [
      {
        title: 'MENU.main',
        url: '/main-new',
        color: '#0984e3',
        icon : 'home'
      },
      {
        title: 'MENU.asset',
        url: '/asset-lists',
        color: '#ff7675',
        icon : 'map'
      },
      {
        title: 'MENU.device',
        url: '/device-lists',
        color: '#ff7675',
        icon: 'water'
      },
      {
        title: 'MENU.sel-asset',
        url: '/sel-asset-type',
        color: '#00cec9',
        icon: 'toggle'
      },
      {
        title: 'MENU.alarm',
        url: '/alarm-list',
        color: '#00cec9',
        icon: 'alert'
      },
      {
        title: 'MENU.logout',
        url:'/logout',
        color : '#2d3436',
        icon : 'log-out'
      }
    ]
  }

  }
  sideMenu()
  {
    this.navigate =
    [
      {
        title: 'MENU.main',
        url: '/main-new',
        color: '#0984e3',
        icon : 'home'
      },
      {
        title: 'MENU.asset',
        url: '/asset-lists',
        color: '#ff7675',
        icon : 'map'
      },
      {
        title: 'MENU.device',
        url: '/device-lists',
        color: '#ff7675',
        icon: 'water'
      },
       {
         title: 'MENU.sel-asset',
         url: '/sel-asset-type',
         color: '#00cec9',
         icon: 'toggle'
       },
       {
        title: 'MENU.contractor',
        url: '/dev-map',
        icon: 'add-circle'
      }, 
      {
        title: 'MENU.logout',
        url:'/logout',
        color : '#2d3436',
        icon : 'log-out'
      }

      /*
      ,
      {
        title: 'Science',
        url: '/home',
        color: '#2d3436'
      },
      {
        title: 'Sports',
        url: '/home',
        color: '#6c5ce7'
      },
      {
        title: 'Technology',
        url: '/home',
        color: '#d63031'
      }*/
    ]
  }
  changeLanguage(lang){
    //console.log(lang);
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
    this.isEn=lang;
  }
}
