import { Injectable, ErrorHandler } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, retry, catchError, timeout } from 'rxjs/operators';
import { UserAuth } from '../models/user-info';
import { Storage } from '@ionic/storage';
import { AssetLists } from '../models/assets';
import { MenuChangeService } from './menu-change.service';
import { Platform } from '@ionic/angular';
import { FileTransfer, FileTransferObject, FileUploadOptions } from '@ionic-native/file-transfer/ngx';


@Injectable({
  providedIn: 'root'
})
export class ThingsboardService {
  //private server_url  ="http://35.247.133.110:8080";
 // private server_url  ="https://mimesolution.com:9434";
  
 private server_url  = "http://196.53.248.237:8080";//"https://smartsolar-th.com:9434";


  //private server_url2 = "http://35.247.133.110";
  //private server_url2 = "https://mimesolution.com:9435";
  private server_url2 = "http://196.53.248.237:8001";//"https://smartsolar-th.com:9433";
 
  private login_url = "/api/auth/login";
  private user_profile_url = "/api/auth/user";

  private fetch_asset_tenant = "/api/tenant/assets";
  private fetch_asset_customer = "/api/customer/%CUSTID%/assets";

  private fetch_device_url = "/api/devices";
  private dev_telemetry_url = "/api/plugins/telemetry/DEVICE/%ID%/values/timeseries";
  private refresh_token_url = "/api/auth/token";

  private fetch_alarm_url ="/api/alarm/ASSET/%ID%?limit=200&ascOrder=true"

  private dev_mgm_url = "/api/v1/save_device";
  private dev_mgm_url_ios = "/api/v1/save_device_ios";
  

  private fetch_asset_new = "/api/v1/%RID%/assets/%UTYPE%";

  constructor(private http: HttpClient, private storage : Storage, private platform : Platform,
    private menuChangeService : MenuChangeService, private transfer: FileTransfer
    ) { }

   refreshToken1(): void {
    console.log("refreshToken()");
    this.storage.get("USER_SECURITY").then(
       (uSec) =>{
          this.refreshToken(uSec.refreshToken).subscribe(
            (res)=>{
                this.storage.set('auth-token',res.token).then(() => {
                  uSec.refreshToken = res.refreshToken;
                  this.storage.set('USER_SECURITY', uSec).then(() => {
                    return;
                  });
                });
            },
             (err_rftk1) =>{
              
                    console.log(err_rftk1);
                    
            }
          )
      }
    );
  }
// Handle API errors
  handleError(error: HttpErrorResponse) {
    console.log("error1");
    let errMsg :any= ""
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
      errMsg = 'An error occurred:'+ error.error.message;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,

      if (error.error != null && error.error != undefined){
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error.message}`);
        errMsg = `Backend returned code ${error.status}, ` + `body was: ${error.error.message}`
        //  if (error.error.message.indexOf("Token has expired") > -1){
        //    this.refreshToken1();
        //  }
      }else{
        console.error("Server Error please retry or contact admin");
        errMsg = "Server Error please retry or contact admin";
      }
    }
    // return an observable with a user-facing error message
    return throwError(
      errMsg
      );
  };



  login(username, pwd) : Observable<any>  {

    let body ='{';
    body+='"username" : "' + username +'",';
    body+='"password" : "' + pwd +'"';
    body+=' }';
  
  
    
    //console.log(body);
    
    let options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    console.log(this.server_url + this.login_url);
    let proxyurl = "";//"https://cors-anywhere.herokuapp.com/";
    console.log( this.server_url + this.login_url);
    return this.http.post( this.server_url + this.login_url, body, options).pipe(
        retry(2),
        timeout(2000),
        catchError(this.handleError)
     
    )
   
  
    
  }


  fetchProfile(token) : Observable<any>  {
    //console.log("fetchProfile=>token=>" + token);
    let options = { 
      headers: new HttpHeaders(
        { 'Content-Type': 'application/json' ,
         'X-Authorization': 'Bearer ' + token
        }
      ) 
    };//


    //console.log(this.server_url + this.user_profile_url +"=>" + options);
 
    return this.http.get( this.server_url + this.user_profile_url, options).pipe(
        retry(2),
        timeout(2000),
        catchError(this.handleError)
     
    );
       
  }

  fetchAsset(token, u_type, uid, limit) : Observable<any>  {
   // console.log("fetchAsset=>token=>" + token);
    console.log("fetchAsset=>token=>");
    let options = { 
      headers: new HttpHeaders(
        { 'Content-Type': 'application/json' ,
         'X-Authorization': 'Bearer ' + token
        }
      ) 
    };//

    var asset_url = "";
    console.log("u_type=>" + u_type +", userauth.custome=>" + UserAuth.CUSTOMER);
    if (u_type == UserAuth.CUSTOMER){
      asset_url = this.fetch_asset_customer.replace("%CUSTID%", uid) + "?limit=100"
    }else{
      asset_url = this.fetch_asset_tenant +  "?limit=100";
    }
    //console.log(asset_url);
    //console.log(this.server_url + this.user_profile_url +"=>" + options);
 
    return this.http.get( this.server_url + asset_url, options).pipe(
        retry(2),
        timeout(2000),
        catchError(this.handleError)
     
    );
       
  }


  fetchAssetNew(token, u_type, uid, is_contractor) : Observable<any>  {
    // console.log("fetchAsset=>token=>" + token);
     console.log("fetchAsset=>token=>");
     let options = { 
       headers: new HttpHeaders(
         { 'Content-Type': 'application/json' ,
          'X-Authorization': 'Bearer ' + token
         }
       ) 
     };//
 
     var asset_url = "";
     console.log("u_type=>" + u_type +", userauth.custome=>" + UserAuth.CUSTOMER);

     //"/api/v1/%RID%/asets/%UTYPE%";
     if (is_contractor){
        asset_url = this.fetch_asset_new.replace("%RID%", uid).replace("%UTYPE%", "P")
     }else {
        if (u_type == UserAuth.CUSTOMER){
          asset_url = this.fetch_asset_new.replace("%RID%", uid).replace("%UTYPE%", "C")
        }else{
          asset_url = this.fetch_asset_new.replace("%RID%", uid).replace("%UTYPE%", "T")
        }
      }

     
     console.log(asset_url);
     //console.log(this.server_url + this.user_profile_url +"=>" + options);
  
     return this.http.get( this.server_url2 + asset_url, options).pipe(
      //  map( (data)=>{
      //    console.log(data);
      //    this.storage.set("ASSETS", data).then(
      //      ()=>{
      //        this.menuChangeService.publishAssetFetchDone();
      //      }
      //    )
      //  }),
         retry(2),
         timeout(2000),
         catchError(this.handleError)
      
     );
        
   }

  fetchDevice(token, asset_id) : Observable<any>  {
   // console.log("fetchDevice=>token=>" + token);
    let options = { 
      headers: new HttpHeaders(
        { 'Content-Type': 'application/json' ,
         'X-Authorization': 'Bearer ' + token
        }
      ) 
    };//

    let body ='{';
    body+='"deviceTypes" : ["PUMP", "LIGHT"],';
    body+='"parameters" : {';
    body+=' "rootId" : "' + asset_id + '",';
    body+=' "rootType": "ASSET",';
    body+=' "direction": "FROM",';
    body+=' "relationTypeGroup": "COMMON",';
    body+=' "maxLevel": 2,';
    body+=' "fetchLastLevelOnly": false';
    
    body+=' },'
    body+='"relationType" : ""'
    body+=' }';
  
  
    
    //console.log(body);
    //console.log("url => " + this.server_url + this.fetch_device_url);
    return this.http.post( this.server_url + this.fetch_device_url, body, options).pipe(
        retry(2),
        timeout(2000),
        catchError(this.handleError)
     
    );
       
  }

  fetchDevice2(token, asset_id, device_type) : Observable<any>  {
    // console.log("fetchDevice=>token=>" + token);
     let options = { 
       headers: new HttpHeaders(
         { 'Content-Type': 'application/json' ,
          'X-Authorization': 'Bearer ' + token
         }
       ) 
     };//
 
     let body ='{';
     body+='"deviceTypes" : ["' + device_type  +  '"],';
     body+='"parameters" : {';
     body+=' "rootId" : "' + asset_id + '",';
     body+=' "rootType": "ASSET",';
     body+=' "direction": "FROM",';
     body+=' "relationTypeGroup": "COMMON",';
     body+=' "maxLevel": 2,';
     body+=' "fetchLastLevelOnly": false';
     
     body+=' },'
     body+='"relationType" : ""'
     body+=' }';
   
   
     
     //console.log(body);
     //console.log("url => " + this.server_url + this.fetch_device_url);
     return this.http.post( this.server_url + this.fetch_device_url, body, options).pipe(
         retry(2),
         timeout(2000),
         catchError(this.handleError)
      
     );
        
   }
  fetchDevTelemetry(token, dev_id) : Observable<any>  {
    //console.log("fetchDevTelemetry=>token=>" );
    let options = { 
      headers: new HttpHeaders(
        { 'Content-Type': 'application/json' ,
         'X-Authorization': 'Bearer ' + token
        }
      ) 
    };
    var url1 = this.dev_telemetry_url.replace("%ID%", dev_id);
    
   // console.log(this.server_url + url1 +"=>" + options);
 
    return this.http.get( this.server_url + url1, options).pipe(
        retry(2),
        timeout(2000),
        catchError(this.handleError)
     
    );
       
  }

  fetchDevTelemetryNew(token, dev_id) : Observable<any>  {
    //console.log("fetchDevTelemetry=>token=>" );
    let options = { 
      headers: new HttpHeaders(
        { 'Content-Type': 'application/json' ,
         'X-Authorization': 'Bearer ' + token
        }
      ) 
    };
    var dev_tele_new_url = "/api/v1/" + dev_id + "/last_tele";
    //var url1 = this.dev_telemetry_url.replace("%ID%", dev_id);
    
   // console.log(this.server_url + url1 +"=>" + options);
   //console.log(this.server_url2 + dev_tele_new_url);
 
    return this.http.get( this.server_url2 + dev_tele_new_url, options).pipe(
        retry(2),
        timeout(2000),
        catchError(this.handleError)
     
    );
       
  }

  fetchAlarm(token, assetId) : Observable<any>  {
    //console.log("fetchDevTelemetry=>token=>" );
    let options = { 
      headers: new HttpHeaders(
        { 'Content-Type': 'application/json' ,
         'X-Authorization': 'Bearer ' + token
        }
      ) 
    };
    var url1 = this.fetch_alarm_url.replace("%ID%", assetId);


    
   // console.log(this.server_url + url1 +"=>" + options);
 
    return this.http.get( this.server_url + url1, options).pipe(
        retry(2),
        timeout(2000),
        catchError(this.handleError)
     
    );
       
  }

  fetchTenant(token) : Observable<any>  {
    //console.log("fetchDevTelemetry=>token=>" );
    let options = { 
      headers: new HttpHeaders(
        { 'Content-Type': 'application/json' ,
         'X-Authorization': 'Bearer ' + token
        }
      ) 
    };
    var url1 = "/api/v1/tenants";

    
   // console.log(this.server_url + url1 +"=>" + options);
 
    return this.http.get( this.server_url2 + url1, options).pipe(
        retry(2),
        timeout(2000),
        catchError(this.handleError)
     
    );
       
  }
  refreshToken(ref_token) : Observable<any>{
    let body ='{';
    body+='"refreshToken" : "' + ref_token +'"';
    body+=' }';
  
  
    
    console.log(body);
    
    let options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    console.log(this.server_url + this.login_url);
    let proxyurl = "";//"https://cors-anywhere.herokuapp.com/";
    
    return this.http.post( this.server_url + this.refresh_token_url, body, options).pipe(
        retry(2),
        timeout(2000),
        catchError(this.handleError)
     
    )
   
  }


  public updateItemImage(params) :Observable<any>{
console.log("updateItemImage");
    // let options = { headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }) };
 
    // let params1 = new HttpParams();

    // //params['img'] = "dfasdfasdfasdfasdfasdfads";
    
    // params1 = params1.append("lat", params['lat']);
    // params1 = params1.append("lng", params['lng']);
    // params1 = params1.append("dev_img", params['img']);
    // console.log(this.server_url2 + this.dev_mgm_url );
    // return this.http.post(this.server_url2 + this.dev_mgm_url , params1, options).pipe(
    //        retry(2),
    //        timeout(2000),
    //        catchError(this.handleError)
       
    //    );
//upload_image2.jsp

// let options = { headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }) };
 
// let params1 = new HttpParams();



// params1 = params1.append("lat", params['lat']);
// params1 = params1.append("lng", params['lng']);
// params1 = params1.append("asset_id", params['asset_id']);
// params1 = params1.append("dev_name", params['dev_name']);
// params1 = params1.append("dev_type", params['dev_type']);
// params1 = params1.append("cont_id", params['cont_id']);
// params1 = params1.append("cust_id", params['cust_id']);
// params1 = params1.append("tenant_id", params['tenant_id']);
// params1 = params1.append("token", params['token']);
//params1 = params1.append("dev_img", params['dev_img']);


// return this.http.post(this.server_url +"upload_image2.jsp" , params1, options).timeout(this.FETCH_TIMEOUT)
// .map(res => res)
// .catch(this.handleError);

      // return this.http.post(this.server_url2 + this.dev_mgm_url , params, options).pipe(
      //  retry(2),
      //  timeout(2000),
      //  catchError(this.handleError)
      // );
  //  console.log(params)
   



      // var oReq = new XMLHttpRequest();
      // oReq.open("POST", this.server_url2 + this.dev_mgm_url, true);
      // oReq.send(params);
      // console.log("upload image done");
   
      // if (this.platform.is("ios")){
      //  params.delete("dev_img");
      //   return this.http.post(this.server_url2 + this.dev_mgm_url_ios , params).pipe(
      //     retry(2),
      //     timeout(2000),
      //     catchError(this.handleError) 
      //   )
       

      // }else {
        
          return this.http.post(this.server_url2 + this.dev_mgm_url , params).pipe(
            retry(2),
            timeout(2000),
            catchError(this.handleError) 
          );
      // }    

  }
  updateItemImage_IOS(params1, photo_data) :Promise<any>{
    console.log("updateItemImage_IOS");
    let headers1 ={ 'Content-Type': 'application/json' };
    const fileTransfer: FileTransferObject = this.transfer.create();
    const headers = new HttpHeaders(headers1);
    const url = "https://smartsolar-th.com:9433/api/v1/save_device";
    let options: FileUploadOptions = {
      fileKey: 'dev_img',
      fileName: "123.jpg",
      chunkedMode: false,
      headers: {},
      params : params1,
      mimeType: 'multipart/form-data',
    }
    var rep = ''
    
   return fileTransfer.upload(photo_data, url, options);

      // .then(async (data) => {
      //   console.log("upload done");
      //   rep = 'true'
      //   return 'true'
      // }, (err) => {
      //   console.log("upload error")
      //   console.log(err)
      //   rep = 'false'
      //   return 'false'
      // })

  
  }
}
