import { EntityId } from './entity-id';
import { Devices } from './devices';
import { AlarmLists, Alarm } from './alarm';

export class Asset {
    id : EntityId;
    createdTime : number;
    additionalInfo : AdditionInfo;
    tenantId : EntityId;
    customerId : EntityId;
    name : string;
    type : string;
    label : string;
    devices : Devices[] = [];
    alarms : Alarm[] = [];
    setTenantId = (t : string) => {
        this.tenantId  = null;
    }


}

export class AssetLists{
    data : Asset[];
    nextPageLink : any;
    hasNext : boolean;
   

}

export class AdditionInfo{
    description : string;
}

export class DeviceAdditionInfo{
    lat : number;
    long : number;
    cont_id : string;

}

export class AdditionInfo2{
    lat : string;
    long : string;

}

export class Tenant{
    id : string;
    title : string;
    assets : Asset2[];
}

export class Asset2{
    id :string;
    name : string;
    cust_id : string;
}