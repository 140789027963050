import { EntityId } from './entity-id';
import { UserAdditionalInfo } from './user-additional-info';

export class UserInfo {
    id : EntityId = new EntityId();
    createTime : number;
    additionalInfo : UserAdditionalInfo;
    tenantId : EntityId;
    customerId : EntityId;
    email : string;
    authority : UserAuth;
    firstName : string;
    lastName : string;
    name : string;
    assetType : string;
}
export class UserSecurity{
    login : string;
    pwd : string;
    refreshToken : string;
}
export enum UserAuth {
    CUSTOMER = "CUSTOMER_USER",
    TENANT = "TENANT_ADMIN"
}
