import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)},
  {
    path: 'dev-map',
    loadChildren: () => import('./dev-map/dev-map.module').then( m => m.DevMapPageModule)
  },
  {
    path: 'asset-lists',
    loadChildren: () => import('./asset-lists/asset-lists.module').then( m => m.AssetListsPageModule)
  },
  {
    path: 'device-lists',
    loadChildren: () => import('./device-lists/device-lists.module').then( m => m.DeviceListsPageModule)
  },
  {
    path: 'device-detail',
    loadChildren: () => import('./device-detail/device-detail.module').then( m => m.DeviceDetailPageModule)
  },
  {
    path: 'asset-detail',
    loadChildren: () => import('./asset-detail/asset-detail.module').then( m => m.AssetDetailPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },/*
  {
    path: 'members', 
    canActivate: [AuthGuard],
    loadChildren: './members/member-routing.module#MemberRoutingModule'
  },*/
  {
    path: 'main',
    loadChildren: () => import('./main/main.module').then( m => m.MainPageModule)
  },
  {
    path: 'logout',
    loadChildren: () => import('./logout/logout.module').then( m => m.LogoutPageModule)
  },
  {
    path: 'asset-devices',
    loadChildren: () => import('./asset-devices/asset-devices.module').then( m => m.AssetDevicesPageModule)
  },
  {
    path: 'asset-detail2',
    loadChildren: () => import('./asset-detail2/asset-detail2.module').then( m => m.AssetDetail2PageModule)
  },
  {
    path: 'asset-detail-map',
    loadChildren: () => import('./asset-detail-map/asset-detail-map.module').then( m => m.AssetDetailMapPageModule)
  },
  {
    path: 'pump-info',
    loadChildren: () => import('./pump-info/pump-info.module').then( m => m.PumpInfoPageModule)
  },
  {
    path: 'dev-diagram',
    loadChildren: () => import('./dev-diagram/dev-diagram.module').then( m => m.DevDiagramPageModule)
  },
  {
    path: 'dev-history',
    loadChildren: () => import('./dev-history/dev-history.module').then( m => m.DevHistoryPageModule)
  },
  {
    path: 'main-new',
    loadChildren: () => import('./main-new/main-new.module').then( m => m.MainNewPageModule)
  },
  {
    path: 'sel-asset-type',
    loadChildren: () => import('./sel-asset-type/sel-asset-type.module').then( m => m.SelAssetTypePageModule)
  },
  {
    path: 'alarm-list',
    loadChildren: () => import('./alarm-list/alarm-list.module').then( m => m.AlarmListPageModule)
  },
  {
    path: 'add-device',
    loadChildren: () => import('./add-device/add-device.module').then( m => m.AddDevicePageModule)
  },
  {
    path: 'sel-asset-web',
    loadChildren: () => import('./sel-asset-web/sel-asset-web.module').then( m => m.SelAssetWebPageModule)
  },
  {
    path: 'login-web',
    loadChildren: () => import('./login-web/login-web.module').then( m => m.LoginWebPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
