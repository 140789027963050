import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Platform, LoadingController, AlertController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { ThingsboardService } from './thingsboard.service';
import { UserSecurity, UserInfo, UserAuth } from '../models/user-info';
import { LoadingService } from './loading.service';
import { AssetLists } from '../models/assets';
import { MenuChangeService } from './menu-change.service';

const TOKEN_KEY ='auth-token';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  user_profile :UserInfo= new UserInfo();
  authenticationState = new BehaviorSubject(false);
  assets : AssetLists = new AssetLists();
  assetTypes : Map<string, any> = new Map();
  //loading :any=null;
  loading_interval :any;
  constructor(private storage: Storage, private plt: Platform, private thbsrv : ThingsboardService,
    public loading: LoadingService, 
    public loadingCtrl :LoadingController, public alertCtrl : AlertController,
    private  menuChangeService : MenuChangeService) { 
    console.log("authen1")
    this.plt.ready().then(() => {
      this.checkToken();
    });
  }
 
  checkToken() {
    this.storage.get(TOKEN_KEY).then(res => {
      //console.log("res=>" + res);
      if (res) {
        this.authenticationState.next(true);
      }
    })
  }
 
  checkDismiss(){
    if (this.loading.isLoading){
      this.loading.dismiss();
    }
    clearInterval(this.loading_interval);
  }
  async login_old(uid, pwd) {
    // uid = "spk1_u001_1@test.com";
     //  pwd = "Test123!";
   // uid = "samutpakran@test.com";
   // pwd = "Test234!";
    this.loading.present();
    //set interval for close dismiss
    this.loading_interval = setInterval(()=> { this.checkDismiss() }, 30000);
    //end
    this.thbsrv.login(uid, pwd).subscribe(
      
      async (res)=>{
        //console.log(res);
        //console.log("res=>" + res);
        //console.log("res.token=>" + res.token);
        this.loading.dismiss();

        //console.log(res.status +"=>" + (res.status ==undefined));
        if (res.status == null || res.status == "" || res.status == undefined){
          return this.storage.set(TOKEN_KEY,res.token).then(() => {
            let uSec = new UserSecurity();
            let tk = res.token;
            uSec.login = uid;
            uSec.pwd=pwd;
            uSec.refreshToken = res.refreshToken;
            this.storage.set('USER_SECURITY', uSec).then(() => {
              //add code to fetch login & asset
              this.thbsrv.fetchProfile(tk).subscribe(

                (res1)=>{
                  console.log("after fetch profile=>before dismiss");
                   //this.loading.dismiss();
                  //console.log("fetch profile=>res=>" + res1);
                  this.user_profile = res1;
                  //console.log(this.user_profile);
                  this.storage.set("profile",this.user_profile).then(async () => {
                    //fetch asset 
                    var uid1 = "";
                    if (this.user_profile.authority == UserAuth.CUSTOMER){
                      uid1 = this.user_profile.customerId.id
                    }
                    this.thbsrv.fetchAsset(tk, this.user_profile.authority, uid1, 100 ).subscribe(
                       (res_asset)=>{
                        // console.log(res_asset);
                        this.loading.dismiss();
                        this.assets = res_asset;                        
                        //count asset type
                      
                        this.assetTypes =new Map();
                        let idx = 0;
                        let max_asset = this.assets.data.length;
                        for (let assetObj of this.assets.data){
                          
                          var cnt = 0;
                          if ( this.assetTypes.has(assetObj.type)  ) {
                             cnt = this.assetTypes.get(assetObj.type);
                          }
                          this.assetTypes.set(assetObj.type, ++cnt);

                          //fetch device 
                        this.thbsrv.fetchDevice(tk, assetObj.id.id).subscribe(
                          (res)=>{
                            //console.log(res);
                            //this.loading.dismiss();
                            assetObj.devices = res;
                            this.storage.set('ASSETS', this.assets).then( () => {
                                idx++;
                                if (idx >= max_asset){
                                  this.authenticationState.next(true);
                                }
                            });
                          } ,
                          async (err1) =>{
                                  this.loading.dismiss();
                                  console.log("error");
                                  let alert =  await this.alertCtrl.create({
                                    message:  err1,
                                    buttons: [
                                      {
                                        text : 'Ok',
                                        handler: () =>{
                                        if ( err1.indexOf("Token has expired")){
                                          this.thbsrv.refreshToken1();
                                        }
                                          
                                        }
                                      }
                                    ]
                                  });
                                  alert.present();
                            
                          }
                          
    
    
                        );
                        if (this.loading.isLoading){
                          this.loading.dismiss();
                        }
                        //end fetch device
                        }
                        //console.log(this.assets);

                        //end count asset type

                        //console.log(this.assets);
                        this.storage.set('ASSETS', this.assets).then( () => {
                          this.storage.set('ASSET_TYPES', this.assetTypes).then( () => {
                            //return ;
                            
                            //this.authenticationState.next(true);
                            

                          }
                          );
                          
                          
                        }


                        )
                      },
                      async (err)=>{
                        //this.loading.dismiss();
                        this.loading.dismiss();
                        console.log("error");
                        let alert =  await this.alertCtrl.create({
                          message:  err,
                          buttons: ['Dismiss']
                        });
                        alert.present();
                      }
                    );
                    //end fetch asset


                  });
                },
                async (err)=>{
                  //this.loading.dismiss();
                  this.loading.dismiss();
                  console.log("error");
                  let alert =  await this.alertCtrl.create({
                    message:  err,
                    buttons: ['Dismiss']
                  });
                  alert.present();
                }

              );


              //this.authenticationState.next(true);
            
            
            
            });
          });
        }else {
          //error display error
          let alert = await this.alertCtrl.create({
            message: res.status + "=>" + res.message,
            buttons: ['Dismiss']
          });
          alert.present();
        }
        
        
        
      },
      async (err)=>{
        this.loading.dismiss();
        console.log("error");
        let alert =  await this.alertCtrl.create({
          message:  err,
          buttons: ['Dismiss']
        });
        alert.present();
      }

    );
    /*
    return this.storage.set(TOKEN_KEY, 'Bearer 1234567').then(() => {
      this.authenticationState.next(true);
    });
    */
  }




  async login(uid, pwd) {
    
    // uid = "spk1_u001_1@test.com";
     //  pwd = "Test123!";
   // uid = "samutpakran@test.com";
   // pwd = "Test234!";
    this.loading.present();
    //set interval for close dismiss
    this.loading_interval = setInterval(()=> { this.checkDismiss() }, 30000);
    //end
    this.thbsrv.login(uid, pwd).subscribe(
      
      async (res)=>{
        //console.log(res);
        //console.log("res=>" + res);
        console.log("res.token=>" + res.token);
        this.loading.dismiss();

        //console.log(res.status +"=>" + (res.status ==undefined));
        if (res.status == null || res.status == "" || res.status == undefined){
          return this.storage.set(TOKEN_KEY,res.token).then(() => {
            let uSec = new UserSecurity();
            let tk = res.token;
            uSec.login = uid;
            uSec.pwd=pwd;
            uSec.refreshToken = res.refreshToken;
            this.storage.set('USER_SECURITY', uSec).then(() => {
              //add code to fetch login & asset
              this.thbsrv.fetchProfile(tk).subscribe(

                (res1)=>{
                  console.log("after fetch profile=>before dismiss");
                   //this.loading.dismiss();
                  //console.log("fetch profile=>res=>" + res1);
                  this.user_profile = res1;
                  //console.log(this.user_profile);
                  this.storage.set("profile",this.user_profile).then(async () => {
                    this.menuChangeService.publishLoginPass(this.user_profile);
                    this.authenticationState.next(true);
                    
                  });
                },
                async (err)=>{
                  //this.loading.dismiss();
                  this.loading.dismiss();
                  console.log("error");
                  let alert =  await this.alertCtrl.create({
                    message:  err,
                    buttons: ['Dismiss']
                  });
                  alert.present();
                }

              );


              //this.authenticationState.next(true);
            
            
            
            });
          });
        }else {
          //error display error
          let alert = await this.alertCtrl.create({
            message: res.status + "=>" + res.message,
            buttons: ['Dismiss']
          });
          alert.present();
        }
        
        
        
      },
      async (err)=>{
        this.loading.dismiss();
        console.log("error");
        let alert =  await this.alertCtrl.create({
          message:  err,
          buttons: ['Dismiss']
        });
        alert.present();
      }

    );

  }
 
  logout() {
    
    return this.storage.clear().then(() => {
      this.authenticationState.next(false);
    });
  }
 
  isAuthenticated() {
    return this.authenticationState.value;
  }
}
