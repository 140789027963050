import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuChangeService {
  private devTypeAnnouceChangeSource = new Subject();
  private loginPassAnnounceSource = new Subject();
  private assetFetchDoneSource = new Subject();
  devTypeChangeSource$ = this.devTypeAnnouceChangeSource.asObservable();
  loginPassceSource$ = this.loginPassAnnounceSource.asObservable();

  assetFetchDoneSource$ = this.assetFetchDoneSource.asObservable();


  publishDevTypeChange(dev_type){
    this.devTypeAnnouceChangeSource.next(dev_type);
  }

  publishLoginPass(uInfo){
    this.loginPassAnnounceSource.next(uInfo);
  }

  publishAssetFetchDone(){
    this.assetFetchDoneSource.next();

  }
}
